import React, { Component } from "react";
import biography1 from "../../../assets/images/biography-1.jpg";

class RuBiography extends Component {
  render() {
    return (
      <div>
        <p>
          <b>Павел Кухта</b> – один из ярчайших гитаристов в Европе. Родился в
          Гродно (Беларусь). Там он получил первое музыкальное образование - в
          музыкальном колледже (класс преподавателя Захарова В.И.). Окончил
          основной курс и магистратуру Белорусской государственной академии
          музыки (класс доцента Гридюшко Е.М.).
        </p>
        <p className="pt-4">
          В творческом активе Павла более двадцати международных гитарных
          конкурсов, проходивших в Австрии, Германии, Израиле, Испании, Италии,
          России, Сербии, США, Украине, Франции, Чехии. Некоторые из них:
        </p>
        <ul>
          <li>
            <p>
              2014 – 2 Международный конкурс гитаристов имени А. Хорева (Санкт-
              Петербург, Россия) – 1 премия и приз зрительских симпатий;
            </p>
          </li>
          <li>
            <p>
              2011 – 6 Международный конкурс “Guitar Gems” (Нетания, Израиль) –
              2 премия;
            </p>
          </li>
          <li>
            <p>
              2011 – 1 Международный конкурс гитаристов в городе Нордхорн
              (Германия) – 1 премия;
            </p>
          </li>
          <li>
            <p>
              2009 – 10 Международный конкурс гитаристов, посвящённый Л. Брауэру
              (Антони, Франция) – 2 премия и приз зрительских симпатий;
            </p>
          </li>
          <li>
            <p>
              2009 – 1 Международный конкурс гитаристов имени А. Фраучи (Москва,
              Россия) – 2 премия (1 премия не присуждалась);
            </p>
          </li>
          <li>
            <p>
              2007 – 9 Международный конкурс гитаристов имени Дж. Дюарта (Руст,
              Австрия) – 1 премия и специальный приз за лучшее исполнение
              обязательного произведения Дж. Дюарта;
            </p>
          </li>
          <li>
            <p>
              2007 – 7 Международный конкурс «Виртуозы гитары» (Санкт-
              Петербург, Россия) – 1 премия;
            </p>
          </li>
          <li>
            <p>
              2004 – 10 Международный конкурс гитаристов имени Ч. Дроздевича
              (Крыница, Польша) – 1 премия;
            </p>
          </li>
        </ul>

        <p className="pt-4 md:pt-0">
          Также Павел Кухта является единственным белорусским гитаристом,
          который удостаивался звания лауреата на самых крупных гитарных форумах
          таких, как:
        </p>

        <ul>
          <li>
            <p>
              2016 – 50 Международный конкурс гитаристов имени Ф. Тарреги
              (Беникасим, Испания) – 2 премия, приз зрительских симпатий и приз
              за лучшую интерпретацию музыки Тарреги;
            </p>
          </li>
          <li>
            <p>
              2016 – 34 международный конкурс гитарной ассоциации Америки
              (Денвер, США) – 3 премия;
            </p>
          </li>
          <li>
            <p>
              2014 – 30 международный конкурс гитаристов имени А. Сеговии
              (Альмуньекар, Испания) – 2 премия;
            </p>
          </li>
          <li>
            <p>
              2014 – 48 Международный конкурс гитаристов имени Ф. Тарреги
              (Беникасим, Испания) – 2 премия и приз зрительских симпатий;
            </p>
          </li>
          <li>
            <p>
              2012 – 45 Международный конкурс гитаристов имени М. Питталуга
              (Алессандрия, Италия) – 3 премия;
            </p>
          </li>
        </ul>
        <p>
          2015 год ознаменовался важным событием в творческой карьере музыканта:
          Павел выиграл престижный международный конкурс в Хайнсберге
          (Германия). Эта победа подарила ему возможность сыграть серию
          концертов в Европе и Азии, а также записать сольный диск на известном
          канадском лейбле Naxos (Канада). Это уже второй по счёту диск Павла. В
          2011 году, в немецком городе Нордхорн состоялась запись премьерного
          компакт-диска музыканта (студия P&amp;C Samsong Productions 2012).
        </p>
        <p>
          Павел Кухта принимает активное участие во многих фестивалях в Австрии,
          Беларуси, Венгрии, Германии, Греции, Израиле, Китае, Литве, Польше,
          России, Словакии, Швейцарии в качестве специального гостя, члена жюри
          и преподавателя мастер-классов. Выступает в престижных концертных
          залах таких, как: Белорусская государственная филармония (Минск,
          Беларусь), Международный дом музыки (Москва, Россия), Государственная
          академическая капелла Санкт-Петербурга (Россия), Национальная
          филармония Украины (Киев), Шанхайская консерватория музыки (Шанхай,
          Китай) и др.
        </p>
        <p>
          Сотрудничал с такими коллективами, как: квартет “Ensemble Perosi”
          (Турин, Италия), “Valencia Orchestra” (Валенсия, Испания), “Orquesta
          Lirica De Castello” (Кастельон, Испания), Orquesta dela Universidad de
          Granada – OUGR - (Гранада, Испания), “Arco” (Москва, Россия),
          Калининградский симфонический оркестр (Калининград, Россия), Камерный
          оркестр «Новая музыка» (Казань, Россия), Калужский молодёжный
          симфонический оркестр (Калуга, Россия), Национальный симфонический
          оркестр (Киев, Украина), Симфонический оркестр города Зелёна Гура
          (Польша), Государственный камерный оркестр Республики Беларусь
          (Минск), Симфонический оркестр Белтелерадиокомпании (Минск),
          Музыкальная капелла «Sonorus» (Минск), ансамбль солистов
          «Классик-Авангард» (Минск), Минский струнный квартет (Беларусь),
          Симфонический оркестр Гродненской капеллы (Гродно, Беларусь),
          Симфонический оркестр Брестского академического театра драмы (Брест,
          Беларусь) и другими коллективами.
        </p>
        <p className="pt-4">
          Особое место в творческой деятельности музыканта занимает
          сотрудничество с известным белорусским композитором Галиной Гореловой.
          В результате совместной работы, появился ряд произведений для гитары
          соло и в ансамбле:
        </p>
        <ul>
          <li>
            <p>
              2014 – «Сумныя прывiды знiкнуўшых замкаў» («Грустные призраки
              исчезнувших замков»), сюита для белорусских цимбал и гитары;
            </p>
          </li>
          <li>
            <p>2015 – Прелюдия и фуга (посвящено Павлу Кухта);</p>
          </li>
          <li>
            <p>2017 – «Харон», Концертино для гитары и струнных;</p>
          </li>
          <li>
            <p>2018 – «Роден» (посвящено Павлу Кухта);</p>
          </li>
          <li>
            <p>2019 – «Библейские мотивы», сюита в 5 ч. (2-я редакция);</p>
          </li>
          <li>
            <p>2023 – «Далёкие колокола» (посвящено Павлу Кухта);</p>
          </li>
          <li>
            <p>2024 – «Брейгель-сюита» в 4-х ч. (посвящено Павлу Кухта);</p>
          </li>
          <li>
            <p>2024 – «Da Lantano» для гитары и струнных;</p>
          </li>
        </ul>
        <p>
          Павел является идейным вдохновителем и участником гитарных проектов в
          Белорусской государственной филармонии с участием таких гитаристов,
          как: Роланд Диенс (Франция), Аниелло Дезидерио (Италия), Анабель
          Монтесинос и Марко Тамайо (Испания-Куба) и др.
        </p>
        <p>
          В 2013году на сцене большого концертного зала Белорусской
          государственной филармонии состоялся совместный концерт Павла с
          легендарным гитаристом и композитором Роландом Диенсом. Весь вечер
          звучала только музыка автора – оригинальные сочинения, джазовые
          обработки, импровизации. А спустя некоторое время композитор посвятил
          Павлу одно из своих новых сочинений - “Blue Montuno”.
        </p>
        <p>
          В 2021 году в большом зале Белорусской государственной филармонии
          состоялась премьера Сонаты для гитары и клавесина выдающегося
          российского композитора Никиты Кошкина. Несмотря на то, что Соната в
          четырёх частях была написана в 2011 году, первое исполнение состоялось
          спустя 10 лет. После успешной премьеры Сонаты, Никита Кошкин сделал
          посвящение исполнителям Павлу Кухта и Марине Ромейко.
        </p>
        <p>Павел является артистом фирмы Savarez.</p>
        <p>
          В настоящее время Павел Кухта является солистом Белорусской
          государственной филармонии и преподавателем Белорусской
          государственной академии музыки.
        </p>
      </div>
    );
  }
}

export default RuBiography;
