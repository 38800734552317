import React, { Component } from "react";
import Recordings from "../Recordings";

class RuRecordings extends Component {
  render() {
    return <Recordings />;
  }
}

export default RuRecordings;
