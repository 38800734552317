import React, { Component } from "react";
import PhotoSlider from "../PhotoSlider";

class RuGallery extends Component {
  render() {
    return (
      <div className="mx-auto">
        <PhotoSlider />
      </div>
    );
  }
}

export default RuGallery;
